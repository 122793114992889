.animationDown {
  animation: Down infinite 1.5s ease-in;
  animation-delay: 500ms;
}

@keyframes Down {
  0% {
    bottom: 0%;
  }
  50% {
    bottom: 5%;
  }
  100% {
    bottom: 0%;
  }
}

.animationUp {
  animation: Up infinite 1.5s ease-in;
}

@keyframes Up {
  0% {
    top: 0%;
  }
  50% {
    top: 5%;
  }
  100% {
    top: 0%;
  }
}

.cardMainContainer {
  width: 300px;
  /* height: 290px; */
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: left;
  padding: 22px;
  border-radius: 20px;
}
.imgContainer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardImage {
  width: 120px;
  margin: 0px auto;
  max-height: 90px;
}
.cardContainer {
  height: 144px;
  display: flex;
  align-items: center;
  margin: auto;
  margin: 0px auto;
}
@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .cardMainContainer {
    width: 280px;
    /* height: 170px; */
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 16px;
    border-radius: 16px;
    /* line-height: 14px; */
  }
  .cardImage {
    width: 100px;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .cardMainContainer {
    width: 260px;
    /* height: 220px; */
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 14px;
    border-radius: 16px;
    /* line-height: 16px; */
  }
  .imgContainer {
    height: 76px;
  }
  .cardImage {
    width: 80px;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 305px) and (max-width: 600px) {
  .cardMainContainer {
    width: 150px;
    /* height: 170px; */
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    border-radius: 16px;
    line-height: 14px;
  }
  .imgContainer {
    height: 55px;
  }
  .cardImage {
    width: 55px;
    margin: 0px auto;
  }
}

/* Swiper.css */
.swiper-container {
    position: relative;
    overflow: hidden;

  }
  
  .swiper-track {
    display: flex;
    /* width: 80%;
    margin: auto; */
    transition: transform 0.5s ease;
  }
  
  .swiper-slide {
    flex: 0 0 100%;
  }
  
  .swiper-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  
  .swiper-button.prev {
    left: 10px;
    z-index: 1000;
  }
  
  .swiper-button.next {
    right: 10px;
    z-index: 1000;

  }
  
.cardMainContainer {
  display: flex;
  /* grid-template-columns: repeat(3, auto); */
  justify-content: space-evenly;
  margin: 60px auto;
  gap: 20px;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .cardMainContainer {
    display: flex;
    /* grid-template-columns: repeat(3, auto); */
    justify-content: space-evenly;
    margin: 60px auto;
    gap: 20px;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .cardMainContainer {
    display: flex;
    /* grid-template-columns: repeat(3, auto); */
    justify-content: space-evenly;
    margin: 60px auto;
    gap: 20px;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 305px) and (max-width: 600px) {
  .cardMainContainer {
    display: flex;
    /* grid-template-columns: repeat(3, auto); */
    justify-content: space-evenly;
    margin: 60px auto;
    gap: 30px;
    flex-wrap: wrap;
  }
}

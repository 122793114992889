.blogFiled{
    border: 1px solid black;
    padding: 4px 10px;
    color: black;
    border-color: rgb(158, 158, 158);
}
.filedContainers{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

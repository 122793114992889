.container {
  position: relative;
  background: radial-gradient(at 50%, rgb(255, 246, 214) 0.1%, transparent);
  top: 20vh;
}

@media only screen and (min-width: 305px) and (max-width: 550px) {
  .container {
    top: 20vh;
  }
}

:root {
  --swiper-navigation-size-client_swiper: 15px;
}

.App {
  /* max-width: 1700px; */
  margin: auto;
  position: relative;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: #aeaeae;
}

.swiper-button-prev {
  color: #ffc900 !important;
  font-weight: 900;
  height: 40px !important;
  text-shadow: -1px 4px 4px rgba(0, 0, 0, 1);
}

.swiper-button-next {
  color: #ffc900 !important;
  font-weight: 900;
  height: 40px !important;
  text-shadow: -1px 4px 4px rgba(0, 0, 0, 1);
}
.swiper-pagination-bullet-active {
  background-color: #ffc900 !important;
}

#blackCard .swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.swiper-slide-active a div {
  background-color: #000 !important;
  color: #fff !important;
}

#servicesSwiper .swiper-pagination-bullet-active {
  background-color: #fff !important;
}
#servicesSwiper .swiper-pagination-bullet {
  border: 1px solid #fff !important;
}

.last-element-child:last-child .last-element-child__border-bottom {
  border-bottom: 2px #000 solid;
}

@media (max-width: 600px) {
  #client_swiper .swiper-button-prev,
  #client_swiper .swiper-button-next {
    top: 40px;
    /* width: 20px; */
    /* height: 20px; */
    color: black !important;
    background: #ffc900;
    padding: 20px;
    border-radius: 50%;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  }

  #client_swiper .swiper-button-next::after,
  #client_swiper .swiper-button-prev::after {
    font-size: var(--swiper-navigation-size-client_swiper);
  }
}

ul .slick-active div {
  background-color: #fff;
}

.slick-dots li {
  width: 10px !important;
  height: 8px !important;
}
.slick-dots li:last-child {
  margin: 0 !important;
}

.container {
  font-family: Montserrat, sans-serif;
  margin-top: 0;
  position: relative;
  top: 1%;
}

.buttons {
  /* border-radius: 10px; */
  background: url("../../../Assets/linedbg2.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.cardMainContainer {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, auto);
  justify-content: space-evenly;
  margin: 60px auto 30px;
  row-gap: 60px;
  /* column-gap:100px; */
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .cardMainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 40px;
    column-gap: 30px;
    margin: 30px auto;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .cardMainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 30px;
    /* column-gap: 20px; */
    margin: 30px auto;
  }
}
@media only screen and (min-width: 601px) and (max-width: 799px) {
  .cardMainContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-around;
    row-gap: 30px;
    column-gap: 30px;
    margin: 30px auto;
  }
}
@media only screen and (min-width: 305px) and (max-width: 600px) {
  .cardMainContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
    row-gap: 20px;
    column-gap: 0;
    margin: 30px auto;
  }
}

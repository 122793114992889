.cardMainContainer {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  margin: 60px auto;
  row-gap: 40px;
}
@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .cardMainContainer {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    row-gap: 20px;
    margin: 30px auto;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .cardMainContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
    row-gap: 20px;
    column-gap: 20px;
    margin: 30px auto;
  }
}
@media only screen and (min-width: 305px) and (max-width: 600px) {
  .cardMainContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
    row-gap: 20px;
    margin: 30px auto;
  }
}

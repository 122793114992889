.menuToggle {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuToggle input {
  display: flex;
  width: 60px;
  height: 60px;
  position: absolute;
  cursor: pointer;
  opacity: 0; /* hide input */
  z-index: 1; /* top of the menu */
}

.menuToggle span {
  width: 25px;
  height: 4px;
  margin-bottom: 4px;
  background: #000;
  border-radius: 4px;
  -webkit-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -moz-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -ms-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -o-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
}

.span1 {
  transform-origin: 4px 0px;
}

.span3 {
  transform-origin: bottom right;
}

.menuToggle input:checked ~ .span1 {
  transform: rotate(45deg) translate(5px);
}
.menuToggle input:checked ~ .span2 {
  transform: rotate(495deg) translate(2px);
}
.menuToggle input:checked ~ .span3 {
  transform: rotate(45deg);
  opacity: 0;
}

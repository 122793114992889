.section {
  font-family: Montserrat, "san-serrat", sans-serif;
  background: linear-gradient(
    to bottom left,
    rgb(254, 236, 156) 10%,
    white 30%
  );
  /* background-position: 145%; */
  background-repeat: no-repeat;
  /* flex: none; */
}

.mainText {
  font-family: Montserrat, "san-serrat", sans-serif;
  /* font-size: 3rem; */
  font-weight: 700;
  text-align: start;
}

.secondaryText {
  font-family: Montserrat, "san-serrat", sans-serif;
  font-weight: 700;
  color: #838688;
}

.buttons {
  background: url("../../../Assets/linedbg2.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.aboutText {
  font-weight: 600;
  font-size: 1.5rem;
}

.descText {
  text-align: justify;
  font-size: 1.2rem;
  font-weight: 400;
}

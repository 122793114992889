.cardMainContainer {
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  padding: 22px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: all 0.3s ease-in;
}

.cardMainContainer:hover {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.imgContainer {
  width: 100%;
  height: 70px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  transition: all 0.3s ease-in;
  text-align: center;
  align-self: center;
}
.cardImage {
  width: 90px;
  height: 90px;
  margin: 0px auto;
}
.cardContainer {
  height: 144px;
  display: flex;
  align-items: center;
  margin: auto;
  margin: 0px auto;
}
.list {
  list-style: none; /* Remove default bullets */
}

.list li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}
@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .cardMainContainer {
    width: 220px;
    height: 240px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 16px;
    border-radius: 16px;
    /* line-height: 14px; */
  }
  .cardImage {
    width: 60px;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .cardMainContainer {
    width: 220px;
    height: 240px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 14px;
    border-radius: 16px;
    /* line-height: 16px; */
  }
  .imgContainer {
    height: 70px;
  }
  .cardImage {
    width: 60px;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 305px) and (max-width: 600px) {
  .cardMainContainer {
    width: 130px;
    height: 165px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 10px;
    border-radius: 16px;
    line-height: 14px;
  }
  .imgContainer {
    height: 44px;
  }
  .cardImage {
    width: 40px;
    margin: 0px auto;
  }
}

nav .logo {
  font-size: 33px;
  /* width: 100%; */
  color: white;
  font-size: 600;
  /* border: 1px solid rgb(0, 255, 38); */
}
.gbjLogoDiv {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
nav .logo svg {
  display: none;
}

nav {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 80px;
  box-shadow: none;
  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 9999;
}

nav ul {
  /* width: 80%; */
  height: 80px;
  align-items: center;
  display: flex;
  /* column-gap: 8px; */
  list-style: none;
  justify-content: space-between;
  text-align: center;
  /* border: 1px solid red; */
}

.heading {
  width: 200px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 60;
  /* border: 1px solid teal; */
}

nav ul li {
  margin: 0px 5px;
  text-align: left;
}

nav ul li a {
  text-decoration: none;
  letter-spacing: 1px;
  padding: 20px 0px;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  line-height: 40px;
}

nav ul li button {
  display: inline-flex;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  /* background: linear-gradient(156deg, #b60b18 0%, #f35460 100%); */
  box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.15);
  border: none;
  color: #fff;
  font-family: Neue Montreal;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  cursor: pointer;
}

/* nav ul li a:hover {
  color: #1b1b1b;
  background: #fff;
} */

/* ----------a tag underline effect--------- */
nav ul li::after {
  content: "";
  height: 1px;
  width: 0;
  margin-top: -8px;
  background: black;
  transition: width 0.5s 150ms ease-in-out;
  display: block;
}
nav ul li:hover::after {
  height: 1px;
  width: 100%;
  color: yellow;
  display: block;
}
@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  nav {
    padding: 20px 40px;
    /* border: 1px solid red; */
  }
  .heading {
    width: 100%;
    /* border: 1px solid rgb(0, 81, 255); */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 60;
  }
  nav .logo svg {
    display: block;
  }
  nav ul {
    position: absolute;
    top: 80px;
    background: #fafafa;
    z-index: 100;
    /* border-right: 2px solid rgb(92, 92, 92); */
    height: 100vh;
    width: 46%;
    display: block;
    text-align: end;
    padding: 0px 20px;
    transition: all 0.5s ease;
    right: -100%;
    opacity: 1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* padding-top: 15px; */
    overflow-y: scroll;
    padding-bottom: 24px;
    padding-top: 100px;
    padding-right: 80px;
  }

  nav ul li {
    /* margin: 20px 0; */
    padding-top: 4px;
    padding-bottom: 8px;
    /* border-top: 1px solid rgb(166, 166, 166); */
    /* width: fit-content; */
    text-align: right;
  }

  .toggle {
    right: 0;
    top: 80px;
  }

  nav ul li a {
    font-size: 20px;
    padding: 6px 0px;
    font-weight: 600;
    color: black;
  }

  /* .heading img {
    width: 46px;
  } */
  /* ----------a tag underline effect--------- */
  nav ul li::after {
    content: "";
    height: none;
    width: 0;
    margin-top: -8px;
    background: none;
    transition: width 0.5s 150ms ease-in-out;
    display: block;
  }
}
@media (max-width: 560px) {
  nav {
    padding: 20px 20px;
  }
  nav ul {
    width: 90%;
    padding-right: 40px;
  }
  .gbjLogoDiv > p {
    display: none;
  }
  /* .heading img {
    width: 40px;
  } */
  nav ul li a {
    font-weight: 600;
  }
}

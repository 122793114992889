.desktopViewContainer {
  position: relative;
}
.serviceContainer {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.desktopDropdownLink {
  position: absolute;
  background-color: white;
  z-index: 100;
}

.mobileViewContiner {
  display: none;
}
.mobileViewButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10px;
  width: 100%;
  column-gap: 10px;
  /* padding-top: -10px; */
  color: black;
  /* background-color: aquamarine; */
  transition: all 0.3s ease;
  padding: 20px 0px;
}
.mobileViewContiner > button > span {
  text-align: right;
  font-weight: 500;
  width: max-content;
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 2px;
  z-index: 100;
}
.mobileViewContiner > ul {
  text-align: right;
  z-index: 100;
}
.dropDownDiv {
  font-size: 16px;
  margin-left: 10px;
  margin-top: 10px;
  /* padding: 4px 6px; */
  color: rgb(255, 255, 255);
  /* border-bottom: 1px solid rgb(169, 169, 169); */
  cursor: pointer;
}
.Content_underline {
  /* color: white; */
}
.Content_underline:hover {
  color: black;
}

/* ---------Underline Hover Effect Css----------- */
.Content_underline::after {
  content: "";
  height: 1px;
  width: 0;
  margin-top: 1px;
  background: black;
  transition: width 0.5s 150ms ease-in-out;
  display: block;
}
.Content_underline:hover::after {
  height: 1px;
  width: 100%;
  color: yellow;
  display: block;
}
@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .desktopViewContainer {
    display: none;
  }
  .mobileViewContiner {
    display: block;
    width: max-content;
    margin-left: auto;
  }
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(4,auto);
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .cardContainer {
        grid-template-columns: repeat(3,auto);
    }   

}

@media only screen and (min-width: 305px) and (max-width: 600px) {
    .cardContainer {
        grid-template-columns: repeat(2,auto);
    }
}